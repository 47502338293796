@import '@/pages/va.module';

.review-box {
  background: linear-gradient(131deg, #f6feff 0%, #ffefef 100%);
  h2{
    margin-bottom: 0;
  }
}
.title {
  font-size: 48px;
  font-weight: bold;
  color: #0e1527;
  font-family: 'Noto Sans Display Blod';
  padding-top: 50px;
  @apply flex justify-center;
}

.itemBox {
  @apply grid grid-cols-2 gap-[25px] pt-[65px] pb-[130px];

  .item {
    background: #ffffff;
    border-radius: 40px;
    min-height: 500px;
    // box-shadow: 0px 15px 50px 0px rgba(168,155,155,0.15);
    @apply px-[48px] pt-[28px] pb-[40px] flex justify-between flex-col;

    .icon {
      width: 21px;
      height: 21px;
    }

    .reviewTitle {
      font-size: 20px;
      font-family: 'Noto Sans Display Blod';
      font-weight: bold;
      color: #0e1527;
      line-height: 30px;
      margin-top: 18px;
      min-height: 56px;
    }

    .content {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #0e1527;
      line-height: 20px;
      margin-top: 30px;
    }

    .atavar {
      width: 80px;
      height: 80px;
    }

    .name {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0e1527;
      margin-left: 20px;
    }

    .type {
      // width: 77px;

      height: 24px;
      background: #fff2f4;
      border-radius: 12px;

      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ff3e5f;

      @apply flex items-center justify-center ml-[14px] px-[17px];
    }
    .typeblue {
      color: rgba(64, 122, 254, 1);
      background-color: rgba(245, 245, 253, 1);
    }
  }
}

.myBullet {
  width: 20px !important;
  height: 3px !important;
  border-radius: 0px !important;
  // background-color: rgba($color: #000000, $alpha: 0.3);
  margin: 0px 5px !important;

  &:first-child {
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }

  &:last-child {
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }
}

.myActiveBullet {
  // width: 100px !important;
  background-color: #1f2226 !important;
}

.myPagination {
  position: absolute;
  bottom: 62px !important;
}
.MReviews {
  display: none;
}
@media screen and (max-width: 1024px) {
  .itemBox {
    @apply grid-cols-1 gap-[0px] pt-[0px] pb-[67px] px-[20px];

    .item {
      @apply px-[24px] py-[28px];
    border-radius: 16px;
      min-height: 637px;
      .icon {
        width: 22px;
        height: 22px;
        // span {
        //   display: inline !important;
        // }
      }
      .reviewTitle {
        margin-top: 22px;
        line-height: 25px;
        font-size: 16px;
      }
      .content {
        margin-top: 16px;
        font-size: 14px;
        line-height: 24px;
      }
      .atavar {
        width: 40px;
        height: 40px;
      }
      .name {
        margin-left: 8px;
        line-height: 25px;
        font-size: 18px;
      }
      .type{
        margin-left: 10px;
      }
    }
  }
  .PcReviews {
    display: none;
  }
  .MReviews {
    display: block;
  }
  .title {
    padding: 36px 0;
    line-height: 35px;
    font-size: 24px;
  }
  .myPagination {
    position: absolute;
    bottom: 18px !important;
  }
}
